import WelcomePage from './views/WelcomePage';
import store from './store';
import { vuexOidcCreateRouterMiddleware } from 'vuex-oidc';

const routes = [
    {
        path: '/',
        meta: {
            isPublic: true,
            hideNavigation: true,
        },
        name: 'Welcome',
        component: WelcomePage,
        redirect: '/calculations',
        beforeEnter: (to, from, next) => {
            if (store.getters['oidcStore/oidcIsAuthenticated']) {
                next('/calculations');
            } else {
                next();
            }
        },
    },
    {
        path: '/oidc-callback',
        name: 'oidcCallback',
        component: () => import('./views/OidcCallback.vue'),
    },
    {
        path: '/oidc-popup-callback', // Needs to match popupRedirectUri in you oidcSettings
        name: 'oidcPopupCallback',
        component: () => import('./views/OidcPopupCallback'),
    },
    {
        path: '/oidc-callback-error', // Needs to match redirect_uri in you oidcSettings
        name: 'oidcCallbackError',
        component: () => import('./views/OidcCallbackError'),
        meta: {
            isPublic: true,
        },
    },
    {
        path: '/no-access:', // Needs to match redirectUri (redirect_uri if you use snake case) in you oidcSettings
        name: 'noAccess',
        meta: {
            hideNavigation: true,
        },
        props: (route) => ({
            feature: route.query.feature,
        }),
        component: () => import('./views/ForbiddenTask.vue'),
    },
    {
        path: '/no-driver-access',
        name: 'noDriverAccess',
        meta: {
            hideNavigation: true,
        },
        component: () => import('./views/ForbiddenTask.vue'),
    },
    {
        path: '/no-timesheet-access',
        name: 'noTimesheetAccess',
        meta: {
            hideNavigation: true,
        },
        component: () => import('./views/ForbiddenTask.vue'),
    },
    {
        path: '/dashboard',
        name: 'Dashboard',
        component: () => import('./views/DashboardPage.vue'),
        meta: {
            hideNavigation: true,
        },
    },
    {
        path: '/calculations',
        name: 'Calculations',
        component: () => import('./views/CalculationsPage.vue'),
        meta: {
            hideNavigation: true,
        },
        beforeEnter: (from, to, next) => {
            next();
        },
    },
    {
        path: '/SelectCalculationType',
        name: 'SelectCalculationType',
        component: () => import('./views/SelectCalculationType.vue'),
    },
    {
        path: '/revenue/:id?',
        name: 'Revenue',
        component: () => import('./views/RevenuePage.vue'),
    },
    {
        path: '/selectvehicletype/:id?',
        name: 'SelectInvestmentType',
        component: () => import('./views/SelectInvestmentType.vue'),
    },
    {
        path: '/vehicledetails/:id?',
        name: 'VehicleDetails',
        component: () => import('./views/VehicleDetails.vue'),
    },
    {
        path: '/instructions',
        name: 'Instructions',
        component: () => import('./views/InstructionsPage.vue'),
    },
    {
        path: '/defaultvalues/:id?',
        name: 'DefaultValues',
        component: () => import('./views/DefaultValues.vue'),
    },
    {
        path: '/investments/:id?',
        name: 'Investments',
        component: () => import('./views/InvestmentsPage.vue'),
    },
    {
        path: '/insertwages/:id?',
        name: 'InsertWages',
        component: () => import('./views/InsertWages.vue'),
    },
    {
        path: '/calculation',
        name: 'Calculation',
        component: () => import('./views/CalculationPage.vue'),
    },
    {
        path: '/profitcalculationdetails/:id?',
        name: 'ProfitCalculationDetails',
        component: () => import('./views/ProfitCalculationDetails.vue'),
    },
    {
        path: '/calculationResultsVue/:id?',
        name: 'CalculationResultsVue',
        component: () => import('./views/CalculationResultsVue.vue'),
    },
    {
        path: '/timebasedcosts/:id?',
        name: 'TimeBasedCosts',
        component: () => import('./views/TimeBasedCosts.vue'),
    },
    {
        path: '/distancebasedcosts/:id?',
        name: 'DistanceBasedCosts',
        component: () => import('./views/DistanceBasedCosts.vue'),
    },
    {
        path: '/pricing/:id?',
        name: 'Pricing',
        component: () => import('./views/PricingPage.vue'),
    },
    {
        path: '/drivers',
        name: 'Drivers',
        component: () => import('./views/DriversPage.vue'),
        meta: {
            hideNavigation: true,
        },
    },
    {
        path: '/importDrivers',
        name: 'Import drivers',
        component: () => import('./views/ImportDriversPage.vue'),
        meta: {
            hideNavigation: true,
        },
    },
    {
        path: '/taskProfitability',
        name: 'TaskProfitability',
        component: () => import('./views/TaskProfitability.vue'),
        meta: {
            hideNavigation: true,
        },
        beforeEnter: (to, from, next) => {
            let temp = store.getters['features'];
            if (temp?.taskProfitability || temp == null) {
                next();
            } else {
                next('/no-access');
            }
        },
    },
    {
        path: '/taskProfitability/task/:taskId',
        name: 'Task',
        component: () => import('./views/TaskPage.vue'),
        meta: {
            hideNavigation: true,
        },
    },
    {
        path: '/taskProfitability/newTask',
        name: 'NewTask',
        component: () => import('./views/NewTask.vue'),
        meta: {
            hideNavigation: true,
        },
    },
    {
        path: '/taskProfitability/editTask/:taskId',
        name: 'EditTask',
        component: () => import('./views/EditTask.vue'),
        meta: {
            hideNavigation: true,
        },
    },
    {
        path: '/taskProfitability/task/:taskId?/invoice',
        name: 'Invoice',
        component: () => import('./views/InvoiceForm.vue'),
        meta: {
            hideNavigation: true,
        },
    },
    {
        path: '/taskProfitability/task/:taskId?/invoice/:invoiceId',
        name: 'EditInvoice',
        component: () => import('./views/InvoiceForm.vue'),
        meta: {
            hideNavigation: true,
        },
    },
    {
        path: '/invoice/:invoiceId/:taskId',
        name: 'Edit Invoice',
        component: () => import('./views/InvoiceForm.vue'),
        meta: {
            hideNavigation: true,
        },
    },
    {
        path: '/newInvoice',
        name: 'New Invoice',
        component: () => import('./views/InvoiceForm.vue'),
        meta: {
            hideNavigation: true,
        },
    },
    {
        path: '/invoices',
        name: 'Invoices',
        component: () => import('./views/InvoicesPage.vue'),
        meta: {
            hideNavigation: true,
        },
    },
    {
        path: '/invoice/:id',
        name: 'Invoice',
        component: () => import('./views/InvoicePage.vue'),
        meta: {
            hideNavigation: true,
        },
    },
    {
        path: '/importInvoices',
        name: 'Import invoices',
        component: () => import('./views/ImportInvoicesPage.vue'),
        meta: {
            hideNavigation: true,
        },
    },
    {
        path: '/timesheet',
        props: (route) => ({
            shiftId: route.query.shiftId,
        }),
        name: 'Timesheet',
        component: () => import('./views/TimesheetPage.vue'),
        meta: {
            hideNavigation: true,
        },
    },
    {
        path: '/payperiods',
        name: 'PayPeriod',
        component: () => import('./views/PayPeriods.vue'),
        meta: {
            hideNavigation: true,
        },
    },
    {
        path: '/routes',
        name: 'RoutesPage',
        component: () => import('./views/RoutesPage.vue'),
        meta: {
            hideNavigation: true,
        },
    },
    {
        path: '/newRoute',
        name: 'NewRoute',
        component: () => import('./views/NewRoute.vue'),
        meta: {
            hideNavigation: true,
        },
    },
    {
        path: '/route/edit/:id',
        name: 'EditRoute',
        component: () => import('./views/EditRoute.vue'),
        props: true,
        meta: {
            hideNavigation: true,
        },
    },
    {
        path: '/vehicle/location-map',
        name: 'VehicleLocationMap',
        component: () => import('./views/VehicleLocationMap.vue'),
        meta: {
            hideNavigation: true,
        },
    },
    {
        path: '/vehicles',
        name: 'Vehicles',
        component: () => import('./views/VehiclesPage.vue'),
        meta: {
            hideNavigation: true,
        },
    },
    {
        path: '/vehicle/fleet-management',
        name: 'FleetManagement',
        component: () => import('./views/FleetManagementPage.vue'),
        meta: {
            hideNavigation: true,
        },
    },
    {
        path: '/sharing',
        name: 'Sharing',
        component: () => import('./views/SharingPage.vue'),
        meta: {
            hideNavigation: true,
        },
    },
    {
        path: '/report',
        name: 'Report',
        component: () => import('./views/ReportPage.vue'),
        meta: {
            hideNavigation: true,
        },
    },
    {
        path: '/analytics',
        name: 'Analytics',
        component: () => import('./views/AnalyticsPage.vue'),
        meta: {
            hideNavigation: true,
        },
    },
    {
        path: '/contact-us',
        name: 'ContactUs',
        component: () => import('./views/ContactPage.vue'),
        meta: {
            hideNavigation: true,
        },
    },
    {
        path: '/userprofile',
        name: 'UserProfile',
        component: () => import('./views/UserProfile.vue'),
        meta: {
            hideNavigation: true,
        },
    },
    {
        path: '/administration',
        name: 'Administration',
        component: () => import('./views/AdministrationPage.vue'),
        meta: {
            hideNavigation: true,
        },
        beforeEnter: (to, from, next) => {
            if (store.getters['roles']?.includes('Admin')) {
                next();
            } else {
                next({ name: 'dashboard' });
            }
        },
    },
    {
        path: '/administration/equipment',
        name: 'AdminVehicles',
        component: () => import('./views/VehiclesPage.vue'),
        meta: {
            hideNavigation: true,
        },
        beforeEnter: (to, from, next) => {
            if (store.getters['roles']?.includes('Admin')) {
                next();
            } else {
                next({ name: 'dashboard' });
            }
        },
    },
    {
        path: '/organisation',
        name: 'Organisation',
        component: () => import('./views/OrganisationPage.vue'),
        meta: {
            hideNavigation: true,
        },
        beforeEnter: (to, from, next) => {
            if (
                store.getters['roles']?.includes('Admin') ||
                store.getters['roles']?.includes('Superuser')
            ) {
                next();
            } else {
                next({ name: 'dashboard' });
            }
        },
    },
    {
        path: '/fuelmanagement/:orgId',
        name: 'FuelManagement',
        component: () => import('./views/FuelManagement.vue'),
        meta: {
            hideNavigation: true,
        },
        beforeEnter: (to, from, next) => {
            if (
                store.getters['roles']?.includes('Admin') ||
                store.getters['roles']?.includes('Superuser')
            ) {
                next();
            } else {
                next({ name: 'dashboard' });
            }
        },
    },
    {
        path: '/mediakit',
        name: 'MediaKit',
        component: () => import('./components/MediaKit.vue'),
    },
];

import { createRouter, createWebHistory } from 'vue-router';
import { appInsights } from './main';

const router = createRouter({
    history: createWebHistory(),
    base: process.env.BASE_URL,
    routes,
    scrollBehavior(to, from, savedPosition) {
        if (savedPosition) {
            return savedPosition;
        } else {
            return { x: 0, y: 0 };
        }
    },
});

// Configure the afterEach guard to track page views
router.afterEach((to) => {
    appInsights.trackPageView({ uri: to.fullPath });
});

router.beforeEach(vuexOidcCreateRouterMiddleware(store, 'oidcStore'));

export default router;
